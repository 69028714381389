.companys {
  width: 218px !important;
  height: 120px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin: 20px 10px;

  &__img {
    height: 60px;
  }
}

.companySldier {
  > div {
    > div {
      > div {
        .slick-active {
          margin-top: unset;
        }
      }
    }
  }
}

.slick-dots {
  bottom: -40px;

  li button::before {
    width: 8px;
    height: 8px;
    // border: #425bff 1px solid;
    background-color: #e1e4e8;
    border-radius: 50%;
    opacity: 1;
    color: transparent;
  }

  li.slick-active button::before {
    background-color: #425bff;
    color: transparent;
    opacity: 1;
  }
}
