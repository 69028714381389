.dashboard {
  background-color: white;
  // overflow-x: hidden;
}

.dashboardHome {
  background-image: url(../../assets/SVG/bg.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -105px;
  height: 1065px;
  overflow-x: hidden;
}

.findForm {
  &__siteUrl {
    // width: 60% !important;
    margin: 0 !important;
    // border: 1px solid #9e9e9e !important;
    box-shadow: none !important;
    padding: 0 0 0 15px !important;
    height: 37px;
  }

  &__button {
    background-color: #75c868 !important;
    color: #ffffff !important;

    // width: 30%;
    margin-left: 15px !important;
    // height: 47px !important;
    // vertical-align: top;
    letter-spacing: unset;
    text-transform: none;
  }
}

.industries {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  >div {
    height: 90px !important;
  }
}

.section1 {
  padding: 200px 0 120px 0;
  // display: flex;
  align-items: center;

  div:first-child {
    p:first-child {
      // font-size: 36px;
      // font-family: CircularStd;
      max-width: 678px;
      font-size: 45px;
      line-height: normal;
      font-weight: bold;
      color: #ffffff;

      span {
        border-bottom: solid 5px;
        border-color: #f35162;
      }
    }

    p:nth-child(2) {
      // font-size: 22px;
      font-size: 24px;
      max-width: 540px;
      line-height: normal;
      font-weight: normal;
      color: #ffffff;
      margin: 50px 0 50px 0;
    }

    .textRank {
      // font-size: 22px;
      font-size: 20px;
      max-width: 506px;
      line-height: normal;
      font-weight: normal;
      // text-decoration: underline;
      color: #a5b5fb;
      margin: 40px 0 50px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboardHome {
    height: unset;
  }

  .section1 {
    padding: 150px 0 150px 0;
    // display: flex;
    align-items: center;

    div:first-child {
      p:first-child {
        // font-size: 36px;
        // font-family: CircularStd;
        max-width: 678px;
        font-size: 31px;
        line-height: normal;
        font-weight: bold;
        color: #ffffff;

        span {
          border-bottom: solid 5px;
          border-color: #f35162;
        }
      }

      p:nth-child(2) {
        // font-size: 22px;
        font-size: 19px;
        max-width: 540px;
        line-height: normal;
        font-weight: normal;
        color: #ffffff;
        margin: 50px 0 50px 0;
      }

      .textRank {
        // font-size: 22px;
        font-size: 18px;
        max-width: 506px;
        line-height: normal;
        font-weight: normal;
        color: #a5b5fb;
        margin: 40px 0 50px 0;
      }
    }
  }

  .section2 {
    padding-bottom: 40px !important;

    >div {
      text-align: center !important;
    }

    &__title {
      font-size: 5vw !important;
    }

    &__text {
      font-size: 3vw !important;
    }

    &__more {
      margin-bottom: 30px;
      margin-left: 33%;
      justify-content: center;
    }
  }

  .section3 {
    padding-top: 80px !important;

    &__title {
      font-size: 5.2vw !important;
    }
  }

  .industries {
    >div {
      height: 80px !important;
    }
  }

  .section5 {
    &__hidediv {
      display: block !important;
    }

    &__body {
      &__text {
        display: none;
      }

      &__order {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .section1 {
    padding: 150px 0 120px 0;
    // display: flex;
    align-items: center;

    div:first-child {
      p:first-child {
        // font-size: 36px;
        // font-family: CircularStd;
        max-width: 678px;
        font-size: 6vw;
        line-height: normal;
        font-weight: bold;
        color: #ffffff;

        span {
          border-bottom: solid 5px;
          border-color: #f35162;
        }
      }

      p:nth-child(2) {
        // font-size: 22px;
        font-size: 22px;
        max-width: 540px;
        line-height: normal;
        font-weight: normal;
        color: #ffffff;
        margin: 50px 0 50px 0;
      }

      .textRank {
        // font-size: 22px;
        font-size: 18px;
        max-width: 506px;
        line-height: normal;
        font-weight: normal;
        color: #a5b5fb;
        margin: 40px 0 50px 0;
      }
    }
  }

  .section4 {
    width: 100%;
    align-items: center;
    padding: 120px 0 80px 0;
    position: relative;

    &__img1 {
      display: none;
    }

    &__img2 {
      display: none;
    }

    &__img3 {
      display: none;
    }

    &__img4 {
      display: none;
    }

    &__img5 {
      display: none;
    }

    &__img6 {
      display: none;
    }

    &__title {
      color: #415089;
      font-size: 35px;
      font-weight: bold;
      padding: 40px 0 120px 0;
    }

    &__point {
      font-size: 250px;
      font-style: italic;
      color: #f4f5f6;
      margin: -200px 0;
    }

    &__text {
      max-width: 625px;
      margin: 0 auto;
      padding: 50px 0 10px 0;
    }

    &__detail {
      padding: 20px 0 80px 0;
    }
  }

  .section6 {
    &__text {
      font-size: 7vw !important;
    }

    &__findForm {
      &__input {
        flex: auto;
        width: 150px;
      }

      &__button {
        width: unset !important;

        &__text {
          display: none;
        }
      }
    }
  }

  .industries {
    >div {
      height: 70px !important;
    }
  }

  .item {
    &__img {
      width: 30px;
      height: 30px;
    }

    &__text {
      font-size: 8px;
    }
  }
}

.section2 {
  padding: 20px 0 100px 0;

  &__img {
    height: 150px;
  }

  &__title {
    font-size: 1.3vw;
    line-height: normal;
    font-weight: bold;
    color: #415089;
    padding: 40px 0 10px 0;
  }

  &__text {
    font-size: 16px !important;
    line-height: 1.7;
    font-weight: normal;
    color: #7e849d;
  }

  &__more {
    display: flex;
    align-items: center;
    color: #1d48f5;
    width: max-content;
    height: 25px;

    >p {
      font-size: 16px;
      font-weight: bold;
      margin: 0 12px 0 0;
      cursor: pointer;
    }

    >img {
      height: 14px;
      margin-top: 3px;
    }
  }

  &__more:hover {
    border-bottom: 2px solid #1d48f5 !important;
  }
}

.section3 {
  // height: 700px;
  padding: 130px 0 100px 0;
  width: 100%;
  background-color: #fafbff;
  position: relative;
  height: 1000px;
  overflow-y: hidden;

  &__title {
    font-weight: bold;
    text-align: center;
    padding: 40px 0 70px 0;
    font-size: 2.1vw;
    margin: 0 auto;
    max-width: 815px;
    color: #415089;
  }

  &__hide {
    width: 100%;
    height: 300px;
    background-image: linear-gradient(to bottom,
        rgba(250, 251, 255, 0.46),
        #fafbff);
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.section4 {
  width: 100%;
  align-items: center;
  padding: 120px 0 80px 0;
  position: relative;

  &__img1 {
    position: absolute;
    top: 40%;
    left: 16%;
    height: 80px;
  }

  &__img2 {
    position: absolute;
    top: 18%;
    left: 0;
    height: 60px;
  }

  &__img3 {
    position: absolute;
    top: 65%;
    left: 10%;
    height: 60px;
  }

  &__img4 {
    position: absolute;
    top: 16%;
    left: 80%;
    height: 60px;
    z-index: 0;
  }

  &__img5 {
    position: absolute;
    top: 30%;
    right: 0;
    height: 60px;
  }

  &__img6 {
    position: absolute;
    top: 61%;
    right: 7%;
    height: 60px;
  }

  &__title {
    color: #415089;
    font-size: 35px;
    font-weight: bold;
    padding: 40px 0 120px 0;
  }

  &__point {
    font-size: 250px;
    font-style: italic;
    color: #f4f5f6;
    margin: -200px 0;
  }

  &__text {
    max-width: 625px;
    margin: 0 auto;
    padding: 50px 0 10px 0;
  }

  &__detail {
    padding: 20px 0 80px 0;
  }
}

.section5 {
  background-color: rgba(255, 250, 250, 0.56);
  padding: 120px 0 100px 0;

  &__hidediv {
    display: none;
  }

  &__title {
    font-size: 40px;
    font-weight: bold;
    color: #415089;
    padding: 40px 0 60px 0;
  }

  &__body {
    margin: 0 auto;

    &__line {
      position: absolute;
      width: 60%;
      border: 1px dashed #d3d4d6;
    }
  }

  &__img {
    height: 70px;
    margin-bottom: 20px;
  }

  &__number {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: dashed 1px #d3d4d6;
    background-color: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #425bff;
  }

  &__text {
    margin-top: 33px;
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 1.75;
    color: #8a909a;
  }
}

.section6 {
  padding: 120px 0;
  background-image: url(../../assets/SVG/bg2.svg);
  background-repeat: no-repeat;
  background-position: center;

  &__underline {
    background-color: #ffffff;
    height: 4px !important;
    width: 70px;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
  }

  &__findForm {
    height: 70px;
    max-width: 570px;
    border-radius: 35px;
    border: solid 1px #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);

    &__input {
      background: transparent;
      border: 0px;
      border-radius: 35px;
      padding-left: 30px;
      outline: none;
      font-size: 20px;
      color: #ffffff;
    }

    &__input::-webkit-input-placeholder {
      opacity: 0.6;
      color: #ffffff;
      font-size: 20px;
    }

    &__button:hover {
      background-color: unset !important;
    }

    &__button {
      height: 60px;
      width: 206px;
      border-radius: 30px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff !important;
      margin-right: 5px;


      &__text {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        //  color: #1d48f5;
        // font-weight: bold !important;
      }
    }
  }
}

.section7 {
  // background-color: rgba(255, 250, 250, 0.56);
  padding: 120px 15px;
  flex-direction: row-reverse;
  display: flex;

  &__title {
    font-size: 40px;
    font-weight: bold;
    color: #415089;
    padding: 40px 0;
  }

  &__text {
    font-size: 16px;
    line-height: 1.75;
    color: #8a909a;
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 50px;
  }
}

.section8 {
  padding-bottom: 120px;

  &__text {
    max-width: 700px;
    margin: 0 auto;
  }
}

.section9 {
  background-color: #f6f7fc;
  padding: 120px 0;
  overflow-x: hidden;

  &__title {
    font-size: 40px;
    font-weight: bold;
    color: #415089;
    padding: 40px 0;
  }

  &__text {
    max-width: 700px;
    margin: 0 auto;
  }
}

button {
  text-transform: none !important;
}