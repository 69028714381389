.topicFinderResult {
  padding-bottom: 60px;

  &__title1 {
    padding: 60px 30px;
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .topicFinderResult {
    &__title1 {
      padding: 60px 0px;
      font-size: 3.5vw;
    }
  }
}

@media screen and (max-width: 576px) {
  .topicFinderResult {
    &__title1 {
      padding: 60px 0px;
      font-size: 4.7vw;
    }
  }
}

.seoWizardResultGuid {
  background-color: #ffffff;
  padding: 100px 10px 130px 10px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (max-width: 500px) {
    padding: 100px 10px 130px 10px;
    height: unset;
    background-size: cover;
  }

  &__underline {
    background-color: #425bff;
    height: 4px !important;
    width: 70px;
    margin-bottom: 40px;
  }

  &__title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #415089;
    margin-block-end: 0px;
  }

  &__smalltitle {
    font-size: 24px;
    line-height: 1.67;
    color: #8a909a;
    padding: 30px 0;
    margin-bottom: 0px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    color: #7e849d;
    max-width: 600px;
    padding: 0px 10px;
    margin-bottom: 60px;
  }

  &__buttontext {
    font-size: 16px;
    color: #ffffff;
  }
}

.seoWizardResult {
  &__header {
    margin-top: -80px;
    background-color: #f3f6ff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background-color: #ffffff;

    @media screen and (max-width: 768px) {
      display: block;
      padding-top: 20px;
    }

    &__text {
      font-size: 22px;
      color: #415089;
      margin-bottom: 0px;
    }

    &__relavancy {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__relavancyText {
      font-size: 16px;
      font-weight: bold;
      color: #7e849d;
      margin-bottom: 0px;
      padding: 16px 0;
    }
  }

  &__relavancy {
    background-color: #f3f6ff;
    padding: 15px 30px;
    margin-bottom: 120px;

    > div > div {
      display: flex;
      align-items: center;
    }

    &__item {
      background-color: #ffffff;
      box-shadow: 0 1px 1px 0 rgba(193, 195, 216, 0.4);
      border-radius: 5px;
      padding: 15px 20px;
      margin: 10px 0;

      &__title {
        margin-left: 20px;
        margin-bottom: 0px;
        color: #415089;
        font-size: 18px;

        @media screen and (max-width: 350px) {
          font-size: 15px;
        }
      }

      &__text {
        margin-bottom: 0px;
        color: #7e849d;
        font-size: 16px;

        @media screen and (max-width: 768px) {
          text-align: justify;
        }
      }
    }
  }
}
