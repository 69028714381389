.wizardPopup {
  // height: 744px;
  width: 660px;
  // border-radius: 20px;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 767px) {
    width: unset;
  }

  &__close {
    width: 30px;
    height: 30px;
  }
}

.wizardPopupHeader {
  height: 290px;
  padding: 60px 0 40px 0;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__text {
    font-size: 16px;
    font-weight: normal;
    color: #7e849d;
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
    padding: 0 15px;
  }
}

.wizardPopupBody {
  display: flex;
  flex-direction: column;
  padding: 0px 60px 40px 60px;

  &__logintext {
    font-size: 18px;
    color: #415089;
    margin-top: 30px;
  }

  &__inputForm:focus {
    border: solid 1px #1d48f5;
    background-color: #ffffff;
  }

  &__inputForm {
    height: 60px;
    border-radius: 5px;
    padding: 0 20px;
    border: solid 1px #d9dcee;
    background-color: #fafbff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 88%;
      border: 0px;
      font-size: 16px;
      color: #415089 !important;
      background-color: #fafbff !important;
      outline: none;
    }
  }

  &__buttomtext {
    font-size: 16px;
    color: #7e849d;
    text-decoration: underline;
    margin-top: -15px;
  }
}

@media screen and (max-width: 767px) {
  .loginHeader {
    padding: 10px;

    &__text {
      font-size: 9vw;
    }
  }

  .dialog {
    div:nth-child(2) {
      div:first-child {
        margin: 10px;
        width: 100% !important;
      }
    }
  }

  .wizardPopup {
    margin: 0px !important;
  }

  .wizardPopupBody {
    padding: 0 20px 30px 20px;
  }
}